import React, {Component} from 'react';
import {Col, Card} from 'react-bootstrap';
import {Link} from "react-router-dom";

class CardButton extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Col>
                <Link to={`/${this.props.link}`} target={this.props.link === 'ranking' ? '_blank' : ''}>
                    <Card>
                        <Card.Body>
                            <Card.Title className='mb-0'>{this.props.title}</Card.Title>
                        </Card.Body>
                    </Card>
                </Link>
            </Col>
        );
    }
}

export default CardButton;