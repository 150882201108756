import CommunicationController from '../../firebase/CommunicationController';
import {actionTypes} from './actionTypes';

export const forza4Actions = {
    getSystem,
    getForza4,
    getRound,
    getTables,
    resetMatches,
    getMatch,
    getUsers
}

function getSystem() {
    return dispatch => {
        const unsubscribe = CommunicationController.INSTANCE.getSystemSnapshot(current_state => {
            dispatch({
                type: actionTypes.FORZA4_CURRENT_STATE,
                current_state,
                unsubscribe
            });
        });
    };
}

function getForza4() {
    return dispatch => {
        const unsubscribe = CommunicationController.INSTANCE.getForza4Snapshot(forza4 => {
            dispatch({
                type: actionTypes.FORZA4_GET_FORZA4,
                forza4,
                unsubscribe
            });
        });
    };
}

function getRound(id) {
    return dispatch => {
        const unsubscribe = CommunicationController.INSTANCE.getForza4RoundSnapshot(id, round => {
            dispatch({
                type: actionTypes.FORZA4_GET_ROUND,
                round,
                unsubscribe
            });
        });
    };
}

function getTables() {
    return dispatch => {
        const unsubscribe = CommunicationController.INSTANCE.getTablesSnapshot(tables => {
            dispatch({
                type: actionTypes.FORZA4_GET_TABLES,
                tables,
                unsubscribe
            });
        });
    };
}

function resetMatches() {
    return dispatch => dispatch({type: actionTypes.FORZA4_MATCHES_RESET});
}

function getMatch(id, round) {
    return dispatch => {
        const unsubscribe = CommunicationController.INSTANCE.getMatchSnapshot(id, round, match => {
            dispatch({
                type: actionTypes.FORZA4_GET_MATCH,
                match,
                unsubscribe
            });
        });
    };
}

function getUsers() {
    return dispatch => {
        const unsubscribe = CommunicationController.INSTANCE.getUsersSnapshot(users => {
            dispatch({
                type: actionTypes.FORZA4_GET_USERS,
                users,
                unsubscribe
            });
        });
    };
}