import {actionTypes} from '../actions/actionTypes';

const initialState = {
    current_state: {},
    quiz: {},
    question: [],
    users: [],
    number_of_votes: 0
};

export default function quizReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.QUIZ_CURRENT_STATE:
            return mergeState({
                current_state: action.current_state,
                unsubscribes: mergeUnsubscribes({get_system: action.unsubscribe})
            });

        case actionTypes.QUIZ_GET_QUIZ:
            return mergeState({quiz: action.quiz});

        case actionTypes.QUIZ_GET_QUESTION:
            return mergeState({
                question: action.question,
                unsubscribes: mergeUnsubscribes({get_question: action.unsubscribe})
            });

        case actionTypes.QUIZ_GET_USERS:
            return mergeState({
                users: action.users,
                unsubscribes: mergeUnsubscribes({get_users: action.unsubscribe})
            });

        case actionTypes.QUIZ_GET_USERS_ANSWERS:
            return mergeState({
                number_of_votes: action.number_of_votes,
                unsubscribes: mergeUnsubscribes({get_users_answers: action.unsubscribe})
            });

        case actionTypes.QUIZ_GET_ANSWERED_QUESTIONS:
            return mergeState({
                number_of_answered_questions: action.number_of_answered_questions,
                unsubscribes: mergeUnsubscribes({get_number_of_answered_questions: action.unsubscribe})
            });

        default:
            return state;
    }

    function mergeState(obj) {
        return {
            ...state,
            ...obj
        }
    }

    function mergeUnsubscribes(unsubscribeObj) {
        return {
            ...state.unsubscribes,
            ...unsubscribeObj
        }
    }
}