import React, {Component} from 'react';
import {Row} from 'react-bootstrap';
import winner_cup from '../images/winner_cup.png';
import Confetti from './Confetti';

class Ranking extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Row id='ranking-container' className='justify-content-center m-0 h-100'>
                {this.props.show_winner && <Confetti/>}
                <span id='ranking-title' className='text-center'>CLASSIFICA</span>
                <div id='ranking'>
                    {this.props.tables.map((table, i) => {
                        return (
                            <div key={i}>
                                {i === 0 && this.props.show_winner && <img src={winner_cup} id='winner-cup' className='winner-cup'/>}
                                <div
                                    className={`d-flex justify-content-between ranking-row ${!table.forza4_eliminated && this.props.client_screen === 'forza4' && 'in-game'} ${i === 0 && this.props.show_winner && 'winner'} px-4 mb-3`}>
                                    <p className='ranking-text mb-0'>
                                        <span className='position'>{`${i + 1}°`}</span>
                                        <span className='mx-3'/>{`${table.name}`}
                                    </p>
                                    <p className='ranking-text mb-0'>{table.points}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Row>
        );
    }
}

export default Ranking;
