import {actionTypes} from '../actions/actionTypes';

const initialState = {
    current_state: {},
    tables: [],
    timer_status: 'stop'
};

export default function liveScreenReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.LIVE_SCREEN_CURRENT_STATE:
            return mergeState({
                current_state: action.current_state,
                unsubscribes: mergeUnsubscribes({get_system: action.unsubscribe})
            });

        case actionTypes.LIVE_SCREEN_GET_TABLES:
            return mergeState({
                tables: action.tables,
                unsubscribes: mergeUnsubscribes({get_system: action.unsubscribe})
            });

        case actionTypes.LIVE_SCREEN_SET_TIMER_STATUS:
            return mergeState({timer_status: action.status});

        default:
            return state;
    }

    function mergeState(obj) {
        return {
            ...state,
            ...obj
        }
    }

    function mergeUnsubscribes(unsubscribeObj) {
        return {
            ...state.unsubscribes,
            ...unsubscribeObj
        }
    }
}
