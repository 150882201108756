import {Component} from 'react';
import Header from '../Components/Header';
import {Button, Container} from 'react-bootstrap';
import {quizActions} from "../store/actions/quizAction";
import {connect} from "react-redux";
import CommunicationController from "../firebase/CommunicationController";

class ManageLiveScreens extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.title = `Nucalathon | Manage Live Screens`;
        this.props.getSystem();
    }

    componentWillUnmount() {
        for (const unsubscribe in this.props.unsubscribes) {
            this.props.unsubscribes[unsubscribe]();
        }
    }

    handleChangeLiveScreen = async (content, liveScreen) => {
        let newLiveScreen = {};
        newLiveScreen[`live_screen_${liveScreen}`] = content;
        let response = await CommunicationController.INSTANCE.editSystem(newLiveScreen);
        if (!response.ok) {
            console.error(await response.text());
            alert('Error');
        }
    }

    render() {
        return (
            <>
                <Header page='manage_live_screen'/>
                <Container className='mt-3' fluid>
                    {[1, 2, 3].map((i) => {
                        return (
                            <div className={i > 1 ? 'mt-5' : ''} key={i}>
                                <h3>{`MANAGE LIVE SCREEN ${i}`}</h3>
                                <div className='d-flex mt-3'>
                                    {['cover one 4 all', 'cover nucalathon', 'cover quiz', 'cover art attack', 'cover forza 4', 'ranking', 'timer', 'live quiz', 'forza 4 finale', 'off'].map((liveScreen, j)=> {
                                        return (
                                            <Button
                                                variant={this.props.current_state[`live_screen_${i}`] === liveScreen ? 'success' : 'danger'}
                                                className={`capitalize me-3 ${liveScreen === 'off' && 'ms-5'}`}
                                                onClick={async () => this.handleChangeLiveScreen(liveScreen, i)}
                                                key={j}>
                                                {liveScreen}
                                            </Button>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}

                </Container>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        current_state: state.quiz.current_state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSystem: () => dispatch(quizActions.getSystem())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageLiveScreens);
