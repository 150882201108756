import {combineReducers} from 'redux'
import headerReducer from '../reducers/headerReducer';
import quizReducer from '../reducers/quizReducer';
import usersReducer from '../reducers/usersReducer';
import forza4Reducer from '../reducers/forza4Reducer';
import liveScreenReducer from '../reducers/liveScreenReducer';
import artAttackReducer from '../reducers/artAttackReducers';

const rootReducer = combineReducers({
    header: headerReducer,
    quiz: quizReducer,
    users: usersReducer,
    forza4: forza4Reducer,
    art_attack: artAttackReducer,
    live_screen: liveScreenReducer
});

export default rootReducer;
