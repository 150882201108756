import {initializeApp} from "firebase/app";
import {getFirestore} from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAwtyJHctow_TRkMfAeOU2n3Efy-EzkRS0",
    authDomain: "gsk-nucala-forza4.firebaseapp.com",
    projectId: "gsk-nucala-forza4",
    storageBucket: "gsk-nucala-forza4.appspot.com",
    messagingSenderId: "718541617458",
    appId: "1:718541617458:web:047ce0a87e56d17d733f2b"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);