import CommunicationController from '../../firebase/CommunicationController';
import {actionTypes} from './actionTypes';

export const headerActions = {
    getSystem
}

function getSystem() {
    return dispatch => {
        const unsubscribe = CommunicationController.INSTANCE.getSystemSnapshot(current_state => {
            dispatch({
                type: actionTypes.NAVBAR_CURRENT_STATE,
                current_state,
                unsubscribe
            });
        });
    };
}