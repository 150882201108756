import {actionTypes} from '../actions/actionTypes';

const initialState = {
    current_state: {},
    forza4: {},
    tables: [],
    matches: [],
    users: []
};

export default function forza4Reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FORZA4_CURRENT_STATE:
            return mergeState({
                current_state: action.current_state,
                unsubscribes: mergeUnsubscribes({get_system: action.unsubscribe})
            });

        case actionTypes.FORZA4_GET_FORZA4:
            return mergeState({
                forza4: action.forza4,
                unsubscribes: mergeUnsubscribes({get_forza4: action.unsubscribe})
            });

        case actionTypes.FORZA4_GET_ROUND:
            return mergeState({
                round: action.round,
                unsubscribes: mergeUnsubscribes({get_round: action.unsubscribe})
            });

        case actionTypes.FORZA4_GET_TABLES:
            return mergeState({
                tables: action.tables,
                unsubscribes: mergeUnsubscribes({get_tables: action.unsubscribe})
            });

        case actionTypes.FORZA4_MATCHES_RESET:
            return mergeState({
                matches: [],
                unsubscribes: mergeUnsubscribes({get_matches: []})
            });

        case actionTypes.FORZA4_GET_MATCH:
            let matches = state.matches;
            let get_matches = state.unsubscribes.get_matches;
            let newMatch = true;

            for (const [i, match] of matches.entries()) {
                if (match.id === action.match.id) {
                    newMatch = false;
                    matches[i] = action.match;
                    break;
                }
            }

            if (newMatch) {
                matches.push(action.match);
                get_matches.push(action.unsubscribe);
            }


            return mergeState({
                matches,
                unsubscribes: mergeUnsubscribes({get_matches})
            });

        case actionTypes.FORZA4_GET_USERS:
            return mergeState({
                users: action.users,
                unsubscribes: mergeUnsubscribes({get_matches: []})
            });

        default:
            return state;
    }

    function mergeState(obj) {
        return {
            ...state,
            ...obj
        }
    }

    function mergeUnsubscribes(unsubscribeObj) {
        return {
            ...state.unsubscribes,
            ...unsubscribeObj
        }
    }
}
