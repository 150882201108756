import React, {Component} from 'react';
import {Container, Nav, Navbar, NavDropdown} from 'react-bootstrap';
import {connect} from 'react-redux';
import {headerActions} from '../store/actions/headerAction';

class Header extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getSystem();
    }

    componentWillUnmount() {
        for (const unsubscribe in this.props.unsubscribes) {
            this.props.unsubscribes[unsubscribe]();
        }
    }

    render() {
        return (
            <Navbar collapseOnSelect expand='lg' bg='dark' variant='dark'>
                <Container fluid>
                    <Navbar.Brand href='/'>GSK NUCALATHON</Navbar.Brand>
                    <Navbar.Toggle aria-controls='navbarScroll'/>
                    <Navbar.Collapse id='navbarScroll'>
                        <Nav
                            className='me-auto my-2 my-lg-0'
                            style={{maxHeight: '100px'}}
                            navbarScroll
                        >
                            <Nav.Link active={this.props.page === 'quiz'} href='/quiz'>QUIZ</Nav.Link>
                            <NavDropdown active={this.props.page === 'forza4'} title='FORZA 4'>
                                <NavDropdown.Item href='/forza4'>FORZA 4</NavDropdown.Item>
                                <NavDropdown.Item href='/forza4/round/1'>ROUND 1</NavDropdown.Item>
                                <NavDropdown.Item href='/forza4/round/2'>ROUND 2</NavDropdown.Item>
                                <NavDropdown.Item href='/forza4/round/3'>ROUND 3</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link active={this.props.page === 'art_attack'} href='/art_attack'>ART ATTACK</Nav.Link>
                            <Nav.Link active={this.props.page === 'users'} href='/users'>USERS</Nav.Link>
                            <Nav.Link active={this.props.page === 'live_screen/manage'} href='/live_screen/manage'>MANAGE LIVE SCREENS</Nav.Link>
                            <NavDropdown active={this.props.page === 'live_screen'} title='LIVE SCREENS'>
                                <NavDropdown.Item href='/live_screen/1' target='_blank'>LIVE SCREEN 1</NavDropdown.Item>
                                <NavDropdown.Item href='/live_screen/2' target='_blank'>LIVE SCREEN 2</NavDropdown.Item>
                                <NavDropdown.Item href='/live_screen/3' target='_blank'>LIVE SCREEN 3</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                    <Navbar.Collapse className='justify-content-end'>
                        <Navbar.Text className='text-white'>{`CLIENT SCREEN: ${this.props.current_state.client_screen}`}</Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }
}

const mapStateToProps = state => {
    return {
        current_state: state.header.current_state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSystem: () => dispatch(headerActions.getSystem())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
