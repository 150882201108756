import {actionTypes} from '../actions/actionTypes';

const initialState = {
    current_state: {}
};

export default function headerReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.NAVBAR_CURRENT_STATE:
            return mergeState({
                current_state: action.current_state,
                unsubscribes: mergeUnsubscribes({get_system: action.unsubscribe})
            });

        default:
            return state;
    }

    function mergeState(obj) {
        return {
            ...state,
            ...obj
        }
    }

    function mergeUnsubscribes(unsubscribeObj) {
        return {
            ...state.unsubscribes,
            ...unsubscribeObj
        }
    }
}