import React, {Component} from 'react';
import Header from '../Components/Header';
import {connect} from 'react-redux';
import {usersActions} from '../store/actions/usersAction';
import {Col, Row, Tab, Nav, Container, Table, Button} from 'react-bootstrap';
import CommunicationController from "../firebase/CommunicationController";
import {forza4Actions} from "../store/actions/forza4Action";
import {headerActions} from "../store/actions/headerAction";

class Users extends Component {

    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount() {
        document.title = `Nucalathon | Users`;
        this.props.getSystem();
        this.props.getUsers();
        this.props.getTables();
    }

    componentWillUnmount() {
        for (const unsubscribe in this.props.unsubscribes) {
            this.props.unsubscribes[unsubscribe]();
        }
    }

    handleMasterClick = async (userId, master) => {
        let response;
        if (master) {
            response = await CommunicationController.INSTANCE.editUser(userId, {master: !master});
            if (!response.ok) {
                console.error(await response.text());
                alert('Error');
            }
        } else {
            response = await CommunicationController.INSTANCE.editUser(userId, {logged_in: false, master: !master});
            if (!response.ok) {
                console.error(await response.text());
                alert('Error');
            }
        }
    }

    handleLogoutClick = async userId => {
        let response = await CommunicationController.INSTANCE.editUser(userId, {logged_in: false});
        if (!response.ok) {
            console.error(await response.text());
            alert('Error');
        }
    }

    render() {
        return (
            <>
                <Header page='users' client_screen={this.props.current_state.client_screen}/>
                <Container className='mt-3' fluid>
                    <h3>USERS</h3>
                    <Tab.Container defaultActiveKey="first">
                        <Row>
                            <Col sm={3}>
                                <Nav variant="pills" className="flex-column">
                                    {this.props.tables.map((table, i) => {
                                        return (
                                            <Nav.Item className='mb-1' key={i}>
                                                <Nav.Link eventKey={table.id}>{table.name}</Nav.Link>
                                            </Nav.Item>
                                        );
                                    })}
                                </Nav>
                            </Col>
                            <Col sm={9}>
                                <Tab.Content>
                                    {this.props.tables.map((table, i) => {
                                        return (
                                            <Tab.Pane eventKey={table.id} key={i}>
                                                <Table hover size="sm" responsive>
                                                    <thead>
                                                    <tr>
                                                        <th>First Name</th>
                                                        <th>Last Name</th>
                                                        <th>Master</th>
                                                        <th>Logged In</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.props.users.map((user, i) => {
                                                        if (table.id === user.id_table) {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{user.name}</td>
                                                                    <td>{user.surname}</td>
                                                                    <td>
                                                                        <Button
                                                                            variant={user.master ? 'success' : 'danger'}
                                                                            onClick={async () => await this.handleMasterClick(user.id, user.master)}>
                                                                            {user.master ? 'Master' : 'Not master'}
                                                                        </Button>
                                                                    </td>
                                                                    <td>{user.logged_in ? (
                                                                        <>
                                                                            <span>Yes</span>
                                                                            <Button variant='danger' className='ms-3' onClick={async () => await this.handleLogoutClick(user.id)}>Logout</Button>
                                                                        </>
                                                                    ) : 'No'}</td>
                                                                </tr>
                                                            );
                                                        }
                                                    })}
                                                    </tbody>
                                                </Table>
                                            </Tab.Pane>
                                        );
                                    })}
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Container>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        current_state: state.header.current_state,
        users: state.users.users,
        tables: state.users.tables,
        unsubscribes: state.users.unsubscribes
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSystem: () => dispatch(headerActions.getSystem()),
        getUsers: () => dispatch(usersActions.getUsers()),
        getTables: () => dispatch(usersActions.getTables())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
