export const actionTypes = {

    /**** NAVBAR ACTIONS ****/
    NAVBAR_CURRENT_STATE: 'NAVBAR_CURRENT_STATE',

    /**** QUIZ ACTIONS ****/
    QUIZ_CURRENT_STATE: 'QUIZ_CURRENT_STATE',
    QUIZ_GET_QUIZ: 'QUIZ_GET_QUIZ',
    QUIZ_GET_QUESTION: 'QUIZ_GET_QUESTION',
    QUIZ_GET_USERS: 'QUIZ_GET_USERS',
    QUIZ_GET_USERS_ANSWERS: 'QUIZ_GET_USERS_ANSWERS',
    QUIZ_GET_ANSWERED_QUESTIONS: 'QUIZ_GET_ANSWERED_QUESTIONS',

    /**** FORZA4 ACTIONS ****/
    FORZA4_CURRENT_STATE: 'FORZA4_CURRENT_STATE',
    FORZA4_GET_FORZA4: 'FORZA4_GET_FORZA4',
    FORZA4_GET_ROUND: 'FORZA4_GET_ROUND',
    FORZA4_GET_TABLES: 'FORZA4_GET_TABLES',
    FORZA4_MATCHES_RESET: 'FORZA4_MATCHES_RESET',
    FORZA4_GET_MATCH: 'FORZA4_GET_MATCH',
    FORZA4_GET_USERS: 'FORZA4_GET_USERS',

    /**** ART ATTACK ACTIONS ****/
    ART_ATTACK_GET_TABLES: 'ART_ATTACK_GET_TABLES',

    /**** USERS ACTIONS ****/
    USERS_GET_USERS: 'USERS_GET_USERS',
    USERS_GET_TABLES: 'USERS_GET_TABLES',

    /**** LIVE SCREEN ACTIONS ****/
    LIVE_SCREEN_CURRENT_STATE: 'LIVE_SCREEN_CURRENT_STATE',
    LIVE_SCREEN_GET_TABLES: 'LIVE_SCREEN_GET_TABLES',
    LIVE_SCREEN_SET_TIMER_STATUS: 'LIVE_SCREEN_SET_TIMER_STATUS'
}
