import React, {Component} from 'react';
import {connect} from 'react-redux';
import {liveScreenActions} from '../store/actions/liveScreenAction';
import logoOne4All from '../images/logo_one_4_all.png';
import logoGSK from '../images/logo_gsk.png';
import logo4 from '../images/logo_4.png';
import Ranking from '../Components/Ranking';
import Timer from '../libraries/Timer';
import timer from "../libraries/Timer";

class LiveScreen extends Component {

    constructor(props) {
        super(props);

        this.state = {
            first_time: true,
            first_timer_status: true,
            id: this.props.match.params.live_screen,
            timer: new Timer(),
            time: 0,
            reset_timer: false
        }

        this.timerInteval = null;
    }

    componentDidMount() {
        document.title = `Nucalathon | Live Screen ${this.state.id}`;
        this.props.getSystem();
        this.props.getTables();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.current_state && (prevProps && prevProps.current_state !== this.props.current_state)) {
            this.setState({live_screen: this.state.id === '1' ? this.props.current_state.live_screen_1 : this.state.id === '2' ? this.props.current_state.live_screen_2 : this.props.current_state.live_screen_3});
        }

        if (this.state.first_time || (prevProps && prevProps.current_state.set_timeout !== this.props.current_state.set_timeout)) {
            this.setState({
                first_time: false,
                time: this.props.current_state.set_timeout
            });
        }

        if (this.state.first_timer_status || (prevProps && prevProps.current_state.timer_status !== this.props.current_state.timer_status)) {
            if (this.props.current_state.timer_status === 'start') {
                if (this.state.reset_timer) {
                    this.setState({
                        time: this.props.current_state.set_timeout,
                        reset_timer: false
                    });
                }

                this.state.timer.start();
                if (!this.timerInteval) {
                    this.timerInteval = setInterval(() => {
                        let time = this.props.current_state.set_timeout - Math.round(this.state.timer.getTime() / 1000);
                        if (time >= 0) {
                            this.setState({time});
                        }
                    }, 1000);
                }
                this.setState({
                    first_timer_status: false,
                    reset_timer: true
                });
            } else if (this.state.timer.isRunning) {
                clearInterval(this.timerInteval);
                this.timerInteval = null;
                this.state.timer.stop();
                this.state.timer.reset();
                this.setState({time: this.props.current_state.set_timeout});
            }
        }
    }

    componentWillUnmount() {
        for (const unsubscribe in this.props.unsubscribes) {
            this.props.unsubscribes[unsubscribe]();
        }
    }

    renderContent = () => {
        switch (this.state.live_screen) {
            case 'cover one 4 all':
                return (
                    <>
                        <img id='gsk-logo' src={logoGSK}/>
                        <img src={logoOne4All}/>
                    </>
                );

            case 'cover nucalathon':
                return (
                    <>
                        <img id='small-one-4-all-logo' src={logoOne4All}/>
                        <img id='gsk-logo' src={logoGSK}/>
                        <span id='cover-title'>NUCALATHON</span>
                    </>
                );

            case 'cover quiz':
                return (
                    <div id='cover-title'>
                        <p id='title-header'>PROVA 1:</p>
                        <p id='title' className='fw-bold'>QUIZZONE</p>
                    </div>
                );

            case 'cover art attack':
                return (
                    <div id='cover-title'>
                        <p id='title-header'>PROVA 2:</p>
                        <p id='title' className='fw-bold'>ART ATTACK</p>
                    </div>
                );

            case 'cover forza 4':
                return (
                    <div id='cover-title'>
                        <p id='title-header'>PROVA 3:</p>
                        <div className='d-flex justify-content-center align-items-center'>
                            <p id='title' className='fw-bold me-5'>FORZA</p>
                            <img id='logo-4' src={logo4}/>
                        </div>
                    </div>
                );

            case 'timer':
                return (
                    <div id='cover-title' page='timer'>
                        <p id='timer'>{this.state.time}</p>
                    </div>
                );

            case 'live quiz':
                return <iframe src='https://gsk-nucala-forza4.web.app/livequiz.html' className='vw-100 vh-100'></iframe>;

            case 'forza 4 finale':
                return <iframe src='https://gsk-nucala-forza4.web.app/liveboard.html' width='1920' height='1080'></iframe>;

            default:
                return null;
        }

    }

    render() {
        return (
            <div id='live-screen'
                 className={`${this.state.live_screen !== 'ranking' && 'd-flex justify-content-center align-items-center'} vw-100 vh-100`}>
                {this.state.live_screen === 'ranking' ? <Ranking tables={this.props.tables} client_screen={this.props.current_state.client_screen} show_winner={this.props.current_state.show_winner}/> : this.renderContent()}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        current_state: state.live_screen.current_state,
        tables: state.live_screen.tables,
        timer_status: state.live_screen.timer_status
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSystem: () => dispatch(liveScreenActions.getSystem()),
        getTables: () => dispatch(liveScreenActions.getTables())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveScreen);
