import {actionTypes} from '../actions/actionTypes';

const initialState = {
    tables: []
};

export default function artAttackReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.ART_ATTACK_GET_TABLES:
            return mergeState({
                tables: action.tables,
                unsubscribes: mergeUnsubscribes({get_tables: action.unsubscribe})
            });

        default:
            return state;
    }

    function mergeState(obj) {
        return {
            ...state,
            ...obj
        }
    }

    function mergeUnsubscribes(unsubscribeObj) {
        return {
            ...state.unsubscribes,
            ...unsubscribeObj
        }
    }
}
