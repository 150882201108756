import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import './App.scss';
import Home from './Pages/Home';
import Quiz from './Pages/Quiz';
import Forza4 from './Pages/Forza4';
import LiveScreen from './Pages/LiveScreen';
import Users from './Pages/Users';
import ArtAttack from './Pages/ArtAttack';
import Round from './Pages/Round';
import Matches from './Pages/Matches';
import ManageLiveScreen from './Pages/ManageLiveScreens';

function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path='/' component={Home}/>
                <Route exact path='/quiz' component={Quiz}/>
                <Route exact path='/forza4' component={Forza4}/>
                <Route exact path='/forza4/round/:round' component={Round}/>
                <Route exact path='/forza4/round/:round/tables/:tables' component={Matches}/>
                <Route exact path='/art_attack' component={ArtAttack}/>
                <Route exact path='/users' component={Users}/>
                <Route exact path='/live_screen/manage' component={ManageLiveScreen}/>
                <Route exact path='/live_screen/:live_screen' component={LiveScreen}/>
            </Switch>
        </BrowserRouter>
    );
}

export default App;
