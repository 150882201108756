import CommunicationController from '../../firebase/CommunicationController';
import {actionTypes} from './actionTypes';

export const usersActions = {
    getUsers,
    getTables
}

function getUsers() {
    return dispatch => {
        const unsubscribe = CommunicationController.INSTANCE.getUsersSnapshot(users => {
            dispatch({
                type: actionTypes.USERS_GET_USERS,
                users,
                unsubscribe
            });
        });
    };
}

function getTables() {
    return dispatch => {
        const unsubscribe = CommunicationController.INSTANCE.getTablesSnapshot(tables => {
            dispatch({
                type: actionTypes.USERS_GET_TABLES,
                tables,
                unsubscribe
            });
        });
    }
}