import CommunicationController from '../../firebase/CommunicationController';
import {actionTypes} from './actionTypes';

export const quizActions = {
    getSystem,
    getQuiz,
    getQuestion,
    getUsers,
    getUsersAnswers,
    getAnsweredQuestions
}

function getSystem() {
    return dispatch => {
        const unsubscribe = CommunicationController.INSTANCE.getSystemSnapshot(current_state => {
            dispatch({
                type: actionTypes.QUIZ_CURRENT_STATE,
                current_state,
                unsubscribe
            });
        });
    };
}

function getQuiz() {
    return dispatch => {
        CommunicationController.INSTANCE.getQuizSnapshot(quiz => {
            dispatch({
                type: actionTypes.QUIZ_GET_QUIZ,
                quiz
            });
        });
    };
}

function getQuestion(id) {
    return dispatch => {
        const unsubscribe = CommunicationController.INSTANCE.getQuizQuestionSnapshot(id, question => {
            dispatch({
                type: actionTypes.QUIZ_GET_QUESTION,
                question,
                unsubscribe
            });
        });
    };
}

function getUsers() {
    return dispatch => {
        const unsubscribe = CommunicationController.INSTANCE.getUsersSnapshot(users => {
            dispatch({
                type: actionTypes.QUIZ_GET_USERS,
                users,
                unsubscribe
            });
        });
    };
}

function getUsersAnswers(id) {
    return dispatch => {
        const unsubscribe = CommunicationController.INSTANCE.getUsersAnswersSnapshot(id, usersAnswers => {
            dispatch({
                type: actionTypes.QUIZ_GET_USERS_ANSWERS,
                number_of_votes: usersAnswers.length,
                unsubscribe
            });
        });
    };
}

function getAnsweredQuestions() {
    return dispatch => {
        const unsubscribe = CommunicationController.INSTANCE.getAnsweredQuestionsSnapshot(answeredQuestions => {
            dispatch({
                type: actionTypes.QUIZ_GET_ANSWERED_QUESTIONS,
                number_of_answered_questions: answeredQuestions.length,
                unsubscribe
            });
        });
    };
}