import {db} from './firebase-config';
import {doc, collection, orderBy, onSnapshot, query, getCountFromServer, where, getDocs} from 'firebase/firestore';

class CommunicationController {

    constructor() {
        this._baseUrl = 'https://us-central1-gsk-nucala-forza4.cloudfunctions.net/webApi/';

        this._errorMessage = 'Something went wrong';
    }

    static get INSTANCE() {
        if (this._quizCommunicationController === undefined) {
            this._quizCommunicationController = new CommunicationController();
        }

        return this._quizCommunicationController;
    }

    async _submitRequest(url, request) {
        return await fetch(this._baseUrl + url, request);
    }

    async _getData(url = '') {
        try {
            return await this._submitRequest(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    async _postData(url = '', data = undefined) {
        try {
            let request = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            if (data) {
                request.body = JSON.stringify(data);
            }
            return await this._submitRequest(url, request);
        } catch (error) {
            console.error(error);
        }
    }

    async _putData(url = '', data = undefined) {
        try {
            let request = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                mode: 'cors'
            }
            if (data) {
                request.body = JSON.stringify(data);
            }
            return await this._submitRequest(url, request);
        } catch (error) {
            console.error(error);
        }
    }

    async _deleteData(url = '', data = undefined) {
        try {
            let request = {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            if (data) {
                request.body = JSON.stringify(data);
            }
            return await this._submitRequest(url, request);
        } catch (error) {
            console.error(error);
        }
    }

    getSystemSnapshot(callback) {
        return onSnapshot(doc(db, 'system', 'current_state'), current_state => callback(current_state.data()));
    }

    getUsersSnapshot(callback) {
        return onSnapshot(query(collection(db, 'users'), orderBy('name', 'asc'), orderBy('surname', 'asc')), querySnapshot => {
            let users = [];
            querySnapshot.forEach(doc => {
                let user = doc.data();
                user.id = doc.id;
                users.push(user);
            });

            callback(users);
        });
    }

    getTablesSnapshot(callback) {
        return onSnapshot(collection(db, 'tables'), querySnapshot => {
            let tables = [];
            querySnapshot.forEach(doc => {
                let table = doc.data();
                table.id = parseInt(doc.id);
                tables.push(table);
            });
            callback(tables);
        });
    }

    getRankingTablesSnapshot(callback) {
        return onSnapshot(query(collection(db, 'tables'), orderBy('points', 'desc')), querySnapshot => {
            let tables = [];
            querySnapshot.forEach(doc => {
                let table = doc.data();
                table.id = parseInt(doc.id);
                tables.push(table);
            });
            callback(tables);
        });
    }

    getQuizSnapshot(callback) {
        return onSnapshot(doc(db, 'games', 'quiz'), quiz => callback(quiz.data()));
    }

    getQuizQuestionSnapshot(id, callback) {
        return onSnapshot(doc(db, `questions`, `${id}`), question => callback(question.data()));
    }

    async editUser(id, parameters) {
        try {
            return await this._putData(`users/${id}`, parameters);
        } catch (error) {
            console.error(error);
        }
    }

    async editSystem(parameters) {
        try {
            return await this._putData(`system/current_state`, parameters);
        } catch (error) {
            console.error(error);
        }
    }

    async getNewQuestion() {
        try {
            return await this._getData(`quiz/question`);
        } catch (error) {
            console.error(error);
        }
    }

    async editQuiz(parameters) {
        try {
            return await this._putData(`quiz`, parameters);
        } catch (error) {
            console.error(error);
        }
    }

    getUsersAnswersSnapshot(id, callback) {
        return onSnapshot(collection(db, `games/quiz/questions/${id}/users_answers`), querySnapshot => {
            let usersAnswers = [];
            querySnapshot.forEach(doc => {
                let userAnswer = doc.data();
                userAnswer.id = parseInt(doc.id);
                usersAnswers.push(userAnswer);
            });

            callback(usersAnswers);
        });
    }

    async deleteUsersAnswers(id) {
        try {
            return await this._deleteData(`quiz/questions/${id}/users_answers`);
        } catch (error) {
            console.error(error);
        }
    }

    async updateResults() {
        try {
            return await this._postData(`quiz/update_results`);
        } catch (error) {
            console.error(error);
        }
    }

    async addTableBonus() {
        try {
            return await this._postData(`quiz/add_table_bonus`);
        } catch (error) {
            console.error(error);
        }
    }

    getAnsweredQuestionsSnapshot(callback) {
        return onSnapshot(collection(db, `games/quiz/questions`), querySnapshot => {
            let answeredQuestions = [];
            querySnapshot.forEach(doc => {
                let answeredQuestion = doc.data();
                answeredQuestion.id = parseInt(doc.id);
                answeredQuestions.push(answeredQuestion);
            });

            callback(answeredQuestions);
        });
    }

    getForza4Snapshot(callback) {
        return onSnapshot(doc(db, 'games', 'forza4'), forza4 => callback(forza4.data()));
    }

    getForza4RoundSnapshot(id, callback) {
        return onSnapshot(doc(db, 'games/forza4/rounds/', id.toString()), round => callback(round.data()));
    }

    async editForza4(parameters) {
        try {
            return await this._putData(`forza4`, parameters);
        } catch (error) {
            console.error(error);
        }
    }

    async startRound(id) {
        try {
            return await this._postData(`forza4/rounds/${id}/start`);
        } catch (error) {
            console.error(error);
        }
    }

    async stopRound(id) {
        try {
            return await this._postData(`forza4/rounds/${id}/stop`);
        } catch (error) {
            console.error(error);
        }
    }

    getMatchSnapshot(id, round, callback) {
        return onSnapshot(doc(db, `games/forza4/rounds/${round.toString()}/matches`, id), match => {
            let matchId = match.id
            match = match.data();
            match.id = matchId;
            callback(match);
        });
    }

    async getUsersPerTable(table) {
        const snapshot = await getCountFromServer(query(collection(db, 'users'), where('id_table', '==', table)));
        return snapshot.data().count;
    }

    async setupForza4Round(id) {
        try {
            return await this._postData(`forza4/rounds/${id}/setup`);
        } catch (error) {
            console.error(error);
        }
    }

    async resetForza4Round(id) {
        try {
            return await this._postData(`forza4/rounds/${id}/reset`);
        } catch (error) {
            console.error(error);
        }
    }

    async updateResultsForza4Round(parameters) {
        try {
            return await this._postData(`forza4/update_results`, parameters);
        } catch (error) {
            console.error(error);
        }
    }

    async restartForza4Match(id, round) {
        try {
            return await this._postData(`forza4/rounds/${round}/matches/${id}/restart`);
        } catch (error) {
            console.error(error);
        }
    }

    async undoLastForza4Move() {
        try {
            return await this._postData(`forza4/`);
        } catch (error) {
            console.error(error);
        }
    }

    async changeLiveScreen(parameters) {
        try {
            return await this._postData(`live_screen`, parameters);
        } catch (error) {
            console.error(error);
        }
    }

    async addPointsArtAttack(parameters) {
        try {
            return await this._postData(`artattack/add_table_points`, parameters);
        } catch (error) {
            console.error(error);
        }
    }

    async resetQuiz() {
        try {
            return await this._postData(`resetpoints`);
        } catch (error) {
            console.error(error);
        }
    }
}

export default CommunicationController;
