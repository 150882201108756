import {Component} from 'react';
import {Card, Row, Col} from 'react-bootstrap';
import _ from 'lodash';
import {Link} from "react-router-dom";

class Forza4Table extends Component {

    constructor(props) {
        super(props);
    }

    getOpponents = () => {
        let opponents = [];
        let opponents_name = [];
        for (const idTable in this.props.match) {
            for (const table of this.props.tables) {
                if (parseInt(idTable) === table.id) {
                    opponents.push(table.id);
                    opponents_name.push(table.name);
                }
            }

        }

        return [opponents, opponents_name];
    }

    checkMatchOver(opponents) {
        let matchOver = false;

        opponents.forEach(opponent => {
            if (this.props.match[opponent].winner) {
                matchOver = true;
            }
        });

        return matchOver;
    }

    render() {
        let opponents;
        let opponents_name;
        [opponents, opponents_name] = this.getOpponents();
        let matchOver = this.checkMatchOver(opponents);

        return (
            <>
                <Card className='px-0'>
                    <Link to={`/forza4/round/${this.props.round}/tables/${opponents.join('_')}`}><
                        Card.Header className='text-center fs-20 fw-bold'>{opponents_name.join(' vs ')}</Card.Header>
                    </Link>
                    <Card.Body>
                        <Row>
                            {opponents.map((opponent, i) => {
                                return (
                                    <Col sm={6} className='d-flex justify-content-center' style={{background: this.props.match[opponent].winner ? 'green' : matchOver ? 'red' : ''}} key={i}>
                                        <span className='fs-65 fw-bold'>{this.props.match[opponent].victory}</span>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Card.Body>
                </Card>
            </>
        )
    }
}

export default Forza4Table;
