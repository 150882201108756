import React, {Component} from 'react';
import Header from '../Components/Header';
import {connect} from 'react-redux';
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import _ from 'lodash';
import {quizActions} from '../store/actions/quizAction';
import CommunicationController from '../firebase/CommunicationController';
import {headerActions} from '../store/actions/headerAction';
import {liveScreenActions} from '../store/actions/liveScreenAction';

class Quiz extends Component {

    constructor(props) {
        super(props);

        this.state = {
            first_quiz: true,
            first_number_of_votes: true,
            current_question: {
                id: null,
                live: false,
                valid: false,
                show_correct_answer: false,
                number_of_votes: 0
            },
            update_results_done: false,
            add_table_bonus_done: false,
            set_test_questions_done: false,
            reset_quiz_done: false
        }
    }

    componentDidMount() {
        document.title = `Nucalathon | Quiz`;
        this.props.getSystem();
        this.props.getUsers();
        this.props.getQuiz();
        this.props.getAnsweredQuestions();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let newState = {};
        if (this.props.quiz.current_question) {
            if (this.state.first_quiz || prevProps && !(_.isEqual(prevProps.quiz, this.props.quiz))) {
                if (this.props.unsubscribes.get_question) {
                    this.props.unsubscribes.get_question();
                }

                this.props.getQuestion(this.props.quiz.current_question.id);
                newState = _.merge(this.state, {
                    first_quiz: false,
                    first_number_of_votes: true,
                    current_question: {
                        id: this.props.quiz.current_question.id,
                        live: this.props.quiz.current_question.live,
                        valid: this.props.quiz.current_question.valid,
                        show_correct_answer: this.props.quiz.current_question.show_correct_answer
                    }
                });
            }

            if (this.state.first_number_of_votes || prevProps && !(_.isEqual(prevProps.number_of_votes, this.props.number_of_votes))) {
                if (this.props.unsubscribes.get_users_answers) {
                    this.props.unsubscribes.get_users_answers();
                }

                this.props.getUsersAnswers(this.state.current_question.id);
                newState = _.merge(this.state, {
                    first_number_of_votes: false,
                    current_question: {
                        number_of_votes: this.props.number_of_votes
                    }
                });
            }
        }

        if (!(_.isEmpty(newState))) {
            this.setState(newState);
        }
    }

    componentWillUnmount() {
        for (const unsubscribe in this.props.unsubscribes) {
            this.props.unsubscribes[unsubscribe]();
        }
    }

    handlePlayClick = async () => {
        let response = await CommunicationController.INSTANCE.editSystem({client_screen: this.props.current_state.client_screen === 'quiz' ? 'cover' : 'quiz'});
        if (!response.ok) {
            console.error(await response.text());
            alert('Error');
        }
    }

    handleGetNewQuestionClick = async () => {
        let response = await CommunicationController.INSTANCE.getNewQuestion();
        if (response.ok) {
            this.setState({update_results_done: false});
        } else {
            console.error(await response.text());
            alert('Error');
        }
    }

    handleSendStopQuestionClick = async (auto = false) => {
        let response = await CommunicationController.INSTANCE.editQuiz({
            current_question: {
                live: !this.state.current_question.live,
                valid: false,
                update_results_required: this.state.current_question.live,
                show_correct_answer: this.state.current_question.live
            }
        });
        if (response.ok) {
            let timeout;
            if (this.state.current_question.live) {
                let response = await CommunicationController.INSTANCE.editSystem({timer_status: 'start'});
                if (!response.ok) {
                    console.error(await response.text());
                    alert('Error');
                }

                if (this.props.current_state.set_quiz_autostop && this.props.quiz.current_question.live) {
                    await new Promise(resolve => timeout = setTimeout(() => resolve(), this.props.current_state.set_timeout * 1000));
                    if (this.props.quiz.current_question.live) {
                        await this.handleSendStopQuestionClick(true);
                    }

                    response = await CommunicationController.INSTANCE.editSystem({timer_status: 'stop'});
                    if (!response.ok) {
                        console.error(await response.text());
                        alert('Error');
                    }
                }
            } else if (!this.state.current_question.live && !auto) {
                clearTimeout(timeout);
                response = await CommunicationController.INSTANCE.editSystem({timer_status: 'stop'});
                if (!response.ok) {
                    console.error(await response.text());
                    alert('Error');
                }
            }
        } else {
            console.error(await response.text());
            alert('Error');
        }
    }

    handleResetClick = async () => {
        if (confirm('Are you sure you want to reset users answers?')) {
            let response = await CommunicationController.INSTANCE.deleteUsersAnswers(this.state.current_question.id);
            if (!response.ok) {
                console.error(await response.text());
                alert('Error');
            }
        }
    }

    handleUpdateResultsClick = async () => {
        let response = await CommunicationController.INSTANCE.updateResults();
        if (response.ok) {
            this.setState({update_results_done: true});
            //setTimeout(() => this.setState({update_results_done: false}), 3000);
        } else {
            console.error(await response.text());
            alert('Error');
        }
    }

    handleAddTableBonusClick = async () => {
        if (confirm('Are you sure you want to add table bonus?')) {
            let response = await CommunicationController.INSTANCE.addTableBonus();
            if (response.ok) {
                this.setState({add_table_bonus_done: true});
                setTimeout(() => this.setState({add_table_bonus_done: false}), 3000);
            } else {
                console.error(await response.text());
                alert('Error');
            }
        }
    }

    handleResetQuizClick = async () => {
        if (confirm('Are you sure you want to reset quiz?')) {
            let response = await CommunicationController.INSTANCE.resetQuiz();
            if (response.ok) {
                this.setState({reset_quiz_done: true});
                setTimeout(() => this.setState({reset_quiz_done: false}), 3000);
            } else {
                console.error(await response.text());
                alert('Error');
            }
        }
    }

    handleShowCorrectAnswerClick = async () => {
        let response = await CommunicationController.INSTANCE.editQuiz({current_question: {show_correct_answer: !this.state.current_question.show_correct_answer}});
        if (!response.ok) {
            console.error(await response.text());
            alert('Error');
        }
    }

    handleTestQuestionsSubmit = async event => {
        event.preventDefault();

        const formData = new FormData(event.target);
        const formProps = Object.fromEntries(formData);
        formProps.set_quiz_questions_test = parseInt(formProps.set_quiz_questions_test);

        if (formProps.set_quiz_questions_test !== '' && formProps.set_quiz_questions_test >= 0) {
            let response = await CommunicationController.INSTANCE.editSystem(formProps);
            if (response.ok) {
                this.setState({set_test_questions_done: true});
                setTimeout(() => this.setState({set_test_questions_done: false}), 3000);
            } else {
                console.error(await response.text());
                alert('Error');
            }
        }
    }

    handleAutoStopChange = async () => {
        let response = await CommunicationController.INSTANCE.editSystem({set_quiz_autostop: !this.props.current_state.set_quiz_autostop});
        if (!response.ok) {
            console.error(await response.text());
            alert('Error');
        }
    }

    render() {
        return (
            <>
                <Header page='quiz' client_screen={this.props.current_state.client_screen}/>
                <Container className='mt-3' fluid>
                    <h3>QUIZ</h3>
                    <Row>
                        <Col sm={4} className='mt-3'>
                            <Row>
                                <div className='d-flex align-items-center'>
                                    <h5 className='me-2 mb-0'>Quiz action:</h5>
                                    <Button
                                        variant={this.props.current_state.client_screen === 'quiz' ? 'danger' : 'success'}
                                        onClick={async () => await this.handlePlayClick()}>
                                        {this.props.current_state.client_screen === 'quiz' ? 'Stop' : 'Play'}
                                    </Button>
                                </div>
                                <div className='d-flex mt-5'>
                                    <h5 className='me-3'>Autostop after timeout</h5>
                                    <Form.Check type="switch" defaultChecked={this.props.current_state.set_quiz_autostop}
                                                onChange={async () => this.handleAutoStopChange()}/>
                                </div>
                                <Form className='mt-5' onSubmit={event => this.handleTestQuestionsSubmit(event)}>
                                    <Form.Group>
                                        <div className='d-flex align-items-center'>
                                            <Form.Label className='mb-0'>
                                                <h5 className='mb-0'>Test questions</h5>
                                            </Form.Label>
                                            <Col sm={2} className='mx-2'>
                                                <Form.Control type="number" name='set_quiz_questions_test' defaultValue={this.props.current_state.set_quiz_questions_test}/>
                                            </Col>
                                            <Button variant={this.state.set_test_questions_done ? 'success' : 'primary'} type='submit'>Set</Button>
                                        </div>
                                    </Form.Group>
                                </Form>
                                <div className='mt-5'>
                                    <h5>{`Number of questions: ${this.props.number_of_answered_questions}`}</h5>
                                </div>
                                <Col sm={4} className='mt-5'>
                                    <Button
                                        variant={this.state.add_table_bonus_done ? 'success' : 'info'}
                                        onClick={async () => await this.handleAddTableBonusClick()}
                                        disabled={this.props.current_state.client_screen !== 'quiz' || this.state.current_question.live}>
                                        Add table bonus
                                    </Button>
                                </Col>
                                <Col sm={4} className='mt-5'>
                                    <Button
                                        variant={this.state.reset_quiz_done ? 'success' : 'danger'}
                                        onClick={async () => await this.handleResetQuizClick()}
                                        disabled={this.props.current_state.client_screen !== 'quiz' || this.state.current_question.live}>
                                        Reset Quiz
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={8} className='mt-3'>
                            <Row>
                                <Col sm={4}>
                                    <Button
                                        variant='primary'
                                        onClick={async () => await this.handleGetNewQuestionClick()}
                                        //disabled={this.props.current_state.client_screen !== 'quiz' || this.state.current_question.live}
                                        disabled={this.props.quiz.current_question && this.props.quiz.current_question.update_results_required}>
                                        Get new question
                                    </Button>
                                </Col>
                                <h5 className='mt-4'>{`Question ${this.props.quiz.current_question ? this.props.quiz.current_question.id : ''}: ${this.props.question ? this.props.question.text : ''}`}</h5>
                                <div className='mt-3'>
                                    <h5>{`Number of votes: ${this.state.current_question.number_of_votes}`}</h5>
                                </div>
                                <Col className='mt-3'>
                                    <Button variant={this.state.current_question.live ? 'warning' : 'success'}
                                            className='me-3'
                                            onClick={async () => await this.handleSendStopQuestionClick()}
                                            disabled={(this.props.current_state.set_quiz_autostop && this.state.current_question.live) || (this.props.current_state.client_screen !== 'quiz' || (!this.state.current_question.valid && !this.state.current_question.live))}>
                                        {this.state.current_question.live ? 'Stop' : 'Send'}
                                    </Button>
                                    {/*<Button
                                        variant={this.state.current_question.show_correct_answer ? 'warning' : 'success'}
                                        className='me-3'
                                        onClick={async () => await this.handleShowCorrectAnswerClick()}
                                        disabled={this.props.current_state.client_screen !== 'quiz' || this.state.current_question.live || this.state.current_question.valid}>
                                        {this.state.current_question.show_correct_answer ? 'Hide correct answer' : 'Show correct answer'}
                                    </Button>*/}
                                    <Button variant='danger'
                                            onClick={async () => await this.handleResetClick()}
                                            disabled={this.props.current_state.client_screen !== 'quiz' || this.state.current_question.live || this.state.current_question.valid}>
                                        Reset
                                    </Button>
                                </Col>
                                <Col sm={12} className='mt-5'>
                                    <Button
                                        variant={this.state.update_results_done ? 'success' : 'info'}
                                        onClick={async () => await this.handleUpdateResultsClick()}
                                        //disabled={this.props.current_state.client_screen !== 'quiz' || this.state.current_question.live || this.state.current_question.valid}
                                        disabled={this.props.quiz.current_question && !this.props.quiz.current_question.update_results_required}>
                                        Update results
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        current_state: state.header.current_state,
        quiz: state.quiz.quiz,
        question: state.quiz.question,
        users: state.quiz.users,
        unsubscribes: state.quiz.unsubscribes,
        number_of_votes: state.quiz.number_of_votes,
        number_of_answered_questions: state.quiz.number_of_answered_questions
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSystem: () => dispatch(headerActions.getSystem()),
        getQuiz: () => dispatch(quizActions.getQuiz()),
        getQuestion: id => dispatch(quizActions.getQuestion(id)),
        getUsers: () => dispatch(quizActions.getUsers()),
        getUsersAnswers: id => dispatch(quizActions.getUsersAnswers(id)),
        getAnsweredQuestions: () => dispatch(quizActions.getAnsweredQuestions()),
        setTimerStatus: status => dispatch(liveScreenActions.setTimerStatus(status))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Quiz);
