import React, {Component} from 'react';
import Header from '../Components/Header';
import {Col, Container, Row, Form, Button} from 'react-bootstrap';
import {headerActions} from '../store/actions/headerAction';
import {connect} from "react-redux";
import {artAttackActions} from '../store/actions/artAttackAction';
import CommunicationController from "../firebase/CommunicationController";

class ArtAttack extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.title = `Nucalathon | Art Attack`;
        this.props.getSystem();
        this.props.getTables();
    }

    handlePointsSubmit = async event => {
        event.preventDefault();

        const formData = new FormData(event.target);
        const formProps = Object.fromEntries(formData);
        for (const key in formProps) {
            formProps[key] = parseInt(formProps[key]);
        }

        if (confirm('Are you sure you want to add points to the tables?')) {
            let response = await CommunicationController.INSTANCE.addPointsArtAttack(formProps);
            if (response.ok) {
                alert('points added');
            } else {
                console.error(await response.text());
                alert('Error');
            }
        }
    }

    render() {
        return (
            <>
                <Header page='art_attack' client_screen={this.props.current_state.client_screen}/>
                <Container className='mt-3' fluid>
                    <h3>ART ATTACK</h3>
                    <Form onSubmit={async event => await this.handlePointsSubmit(event)}>
                        <Row>
                            {this.props.tables.map((table, i) => {
                                return (
                                    <Col sm={3} className={i < 4 ? 'mt-3' : 'mt-4'} key={i}>
                                        <h5>{`Table: ${table.name}`}</h5>
                                        <Form.Control type='number' name={table.id} placeholder="Points" defaultValue={`${table.artattack_points}`}/>
                                    </Col>
                                );
                            })}
                            <div className='d-flex justify-content-center mt-3'>
                                <Button type='submit' variant='success' className='mt-2'>Add points</Button>
                            </div>
                        </Row>
                    </Form>
                    <h4 className='mt-3'>Art Attack points:</h4>
                    {this.props.tables.map((table, i) => {
                        return (
                            <div className='d-flex'>
                                <h5>{`Table: ${table.name}: ${table.artattack_points}`}</h5>
                            </div>
                        );
                    })}
                </Container>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        current_state: state.header.current_state,
        tables: state.art_attack.tables
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSystem: () => dispatch(headerActions.getSystem()),
        getTables: () => dispatch(artAttackActions.getTables())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtAttack);
