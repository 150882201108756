import CommunicationController from '../../firebase/CommunicationController';
import {actionTypes} from './actionTypes';

export const liveScreenActions = {
    getSystem,
    getTables,
    setTimerStatus
}

function getSystem() {
    return dispatch => {
        const unsubscribe = CommunicationController.INSTANCE.getSystemSnapshot(current_state => {
            dispatch({
                type: actionTypes.LIVE_SCREEN_CURRENT_STATE,
                current_state,
                unsubscribe
            });
        });
    };
}

function getTables() {
    return dispatch => {
        const unsubscribe = CommunicationController.INSTANCE.getRankingTablesSnapshot(tables => {
            dispatch({
                type: actionTypes.LIVE_SCREEN_GET_TABLES,
                tables,
                unsubscribe
            });
        });
    };
}

function setTimerStatus(status) {
    return dispatch => {
        dispatch({
            type: actionTypes.LIVE_SCREEN_SET_TIMER_STATUS,
            status
        });
    };
}
