import {actionTypes} from '../actions/actionTypes';

const initialState = {
    users: [],
    tables: [],
    unsubscribes: []
};

export default function usersReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.USERS_GET_USERS:
            return mergeState({
                users: action.users,
                unsubscribes: mergeUnsubscribes({get_users: action.unsubscribe})
            });

        case actionTypes.USERS_GET_TABLES:
            return mergeState({
                tables: action.tables,
                unsubscribes: mergeUnsubscribes({get_tables: action.unsubscribe})
            });

        default:
            return state;
    }

    function mergeState(obj) {
        return {
            ...state,
            ...obj
        }
    }

    function mergeUnsubscribes(unsubscribeObj) {
        return {
            ...state.unsubscribes,
            ...unsubscribeObj
        }
    }
}

