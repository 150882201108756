import CommunicationController from "../../firebase/CommunicationController";
import {actionTypes} from "./actionTypes";

export const artAttackActions = {
    getTables
}

function getTables() {
    return dispatch => {
        const unsubscribe = CommunicationController.INSTANCE.getTablesSnapshot(tables => {
            dispatch({
                type: actionTypes.ART_ATTACK_GET_TABLES,
                tables,
                unsubscribe
            });
        });
    };
}
