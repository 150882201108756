import React, {Component} from 'react';
import Header from '../Components/Header';
import {forza4Actions} from '../store/actions/forza4Action';
import {connect} from 'react-redux';
import {Button, Container, Table} from 'react-bootstrap';
import _ from 'lodash';
import CommunicationController from '../firebase/CommunicationController';

class Matches extends Component {

    constructor(props) {
        super(props);

        this.state = {
            first_round: true,
            first_matches: true,
            round: parseInt(this.props.match.params.round),
            tables: this.props.match.params.tables.split('_'),
            opponents: {},
            restart_done: false,
            undo_last_move_done: false
        }
    }

    componentDidMount() {
        document.title = `Nucalathon | Forza 4 - Round ${this.state.round} - Tables ${this.state.tables.join(' - ')}`;
        this.props.getUsers();
        this.props.getTables();
        this.props.resetMatches();
        this.props.getRound(this.state.round);
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        let newState = {};
        if (this.props.round && (this.state.first_round || prevProps && !(_.isEqual(prevProps.round, this.props.round)))) {
            this.getMatches();
        }

        let usersPerTable = await CommunicationController.INSTANCE.getUsersPerTable(parseInt(this.state.tables[0]));

        if (((this.state.round !== 3 && this.props.matches.length === usersPerTable) || (this.state.round === 3 && this.props.matches.length === 1)) && (this.state.first_matches || prevProps && !(_.isEqual(prevProps.matches, this.props.matches)))) {
            newState.opponents = {};
            for (const [i, match] of this.props.matches.entries()) {
                for (const opponent of this.props.matches[i].users_opponents) {
                    for (const user of this.props.users) {
                        if (user.id === opponent) {
                            newState = _.merge(this.state, {first_matches: false});
                            newState.opponents[user.id] = {
                                name: user.name,
                                surname: user.surname
                            }
                            break;
                        }
                    }
                }
            }
        }

        if (!(_.isEmpty(newState))) {
            this.setState(newState);
        }
    }

    componentWillUnmount() {
        if (this.props.unsubscribes) {
            for (const unsubscribe in this.props.unsubscribes) {
                if (Array.isArray(this.props.unsubscribes[unsubscribe])) {
                    for (const unsubscribeElement of this.props.unsubscribes[unsubscribe]) {
                        unsubscribeElement();
                    }
                } else {
                    this.props.unsubscribes[unsubscribe]();
                }
            }
        }
    }

    handleRestartClick = async id => {
        if (confirm('Are you sure you want to restart the game?')) {
            let response = await CommunicationController.INSTANCE.restartForza4Match(id, this.state.round);
            if (response.ok) {
                this.setState({restart_done: true});
                setTimeout(() => this.setState({restart_done: false}), 3000);
            } else {
                console.error(await response.text());
                alert('Error');
            }
        }
    }

    handleUndoLastMoveClick = async () => {
        if (confirm('Are you sure you want to undo last move?')) {
            let response = await CommunicationController.INSTANCE.undoLastForza4Move();
            if (response.ok) {
                this.setState({undo_last_move_done: true});
                setTimeout(() => this.setState({undo_last_move_done: false}), 3000);
            } else {
                console.error(await response.text());
                alert('Error');
            }
        }
    }

    getMatches = () => {
        this.props.resetMatches();
        for (const [i, opponents] of this.props.round.tables_opponents.entries()) {
            if (opponents.hasOwnProperty(this.state.tables[0]) && opponents.hasOwnProperty(this.state.tables[1])) {
                for (const match of this.props.round.tables_opponents[i].matches) {
                    this.props.getMatch(match, this.state.round);
                }
                break;
            }
        }

        this.setState({first_round: false});
    }

    render() {
        return (
            <>
                <Header page='forza4'/>
                <Container className='mt-3' fluid>
                    <h3>{`FORZA 4 - ROUND ${this.state.round} - ${this.props.tables.filter(table => table.id === parseInt(this.state.tables[0]))[0].name} vs ${this.props.tables.filter(table => table.id === parseInt(this.state.tables[1]))[0].name}`}</h3>
                    <Table hover size="sm" className='mt-3' responsive>
                        <thead>
                        <tr>
                            <th>Player 1</th>
                            <th>Player 2</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {!(_.isEmpty(this.props.matches)) && !(_.isEmpty(this.state.opponents)) && (
                            this.props.matches.map((match, i) => {
                                if (this.state.opponents[match.users_opponents[0]] && this.state.opponents[match.users_opponents[1]]) {
                                    let style1 = {};
                                    let style2 = {};

                                    if (match.winner === match.users_opponents[0]) {
                                        style1 = {
                                            background: 'green',
                                            color: 'white'
                                        }
                                    }

                                    if (match.winner === match.users_opponents[1]) {
                                        style2 = {
                                            background: 'green',
                                            color: 'white'
                                        }
                                    }

                                    if (match.winner !== '') {
                                        if (_.isEmpty(style1)) {
                                            style1 = {
                                                background: 'red',
                                                color: 'white'
                                            }
                                        } else {
                                            style2 = {
                                                background: 'red',
                                                color: 'white'
                                            }
                                        }
                                    }

                                    return (
                                        <tr key={i}>
                                            <td style={style1}>{`${this.state.opponents[match.users_opponents[0]].name} ${this.state.opponents[match.users_opponents[0]].surname}`}</td>
                                            <td style={style2}>{`${this.state.opponents[match.users_opponents[1]].name} ${this.state.opponents[match.users_opponents[1]].surname}`}</td>
                                            <td>{match.status}</td>
                                            <td>
                                                {['game', 'tiebreak'].includes(match.status) && !this.props.round.played && (
                                                    <>
                                                        <Button variant={this.state.add_table_bonus_done ? 'success' : 'danger'} className='me-4'
                                                                onClick={async () => this.handleRestartClick(match.id)}>
                                                            Restart Game
                                                        </Button>
                                                        <Button variant={this.state.add_table_bonus_done ? 'success' : 'warning'}
                                                                onClick={async () => this.handleUndoLastMoveClick()}>
                                                            undo last move
                                                        </Button>
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                }
                            })
                        )}
                        </tbody>
                    </Table>
                </Container>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        unsubscribes: state.forza4.unsubscribes,
        round: state.forza4.round,
        matches: state.forza4.matches,
        users: state.forza4.users,
        tables: state.forza4.tables
    }
}

const mapDispatchToProps = dispatch => {
    return {
        resetMatches: () => dispatch(forza4Actions.resetMatches()),
        getRound: id => dispatch(forza4Actions.getRound(id)),
        getMatch: (id, round) => dispatch(forza4Actions.getMatch(id, round)),
        getUsers: () => dispatch(forza4Actions.getUsers()),
        getTables: () => dispatch(forza4Actions.getTables())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Matches);
