import React, {Component} from 'react';
import {Container, Row} from 'react-bootstrap';
import Header from '../Components/Header';
import CardButton from '../Components/CardButton';

class Home extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <Header/>
                <Container fluid>
                    <Row className='mt-3'>
                        <CardButton link='quiz' title='QUIZ'/>
                        <CardButton link='forza4' title='FORZA 4'/>
                    </Row>
                    <Row className='mt-4'>
                        <CardButton link='users' title='USERS'/>
                        <CardButton link='ranking' title='RANKING'/>
                    </Row>
                </Container>
            </>
        )
    }
}

export default Home;
