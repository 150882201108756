import React, {Component} from 'react';
import Header from '../Components/Header';
import {Button, Container, Row} from 'react-bootstrap';
import CardButton from '../Components/CardButton';
import {connect} from 'react-redux';
import forza4 from "./Forza4";
import CommunicationController from "../firebase/CommunicationController";
import {headerActions} from "../store/actions/headerAction";

class Forza4 extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getSystem();
    }

    componentWillUnmount() {
        for (const unsubscribe in this.props.unsubscribes) {
            if (unsubscribe !== 'get_matches') {
                this.props.unsubscribes[unsubscribe]();
            }
        }
    }

    handlePlayStopClick = async () => {
        let response = await CommunicationController.INSTANCE.editSystem({client_screen: this.props.current_state.client_screen === 'forza4' ? 'cover' : 'forza4'});
        if (!response.ok) {
            console.error(await response.text());
            alert('Error');
        }
    }

    render() {
        return (
            <>
                <Header page='forza4'/>
                <Container className='mt-3' fluid>
                    <h3>FORZA 4</h3>
                    <div className='d-flex align-items-center mt-3'>
                        <h5 className='me-2 mb-0'>Forza 4 action:</h5>
                        <Button
                            variant={this.props.current_state.client_screen === 'forza4' ? 'danger' : 'success'}
                            onClick={async () => await this.handlePlayStopClick()}>
                            {this.props.current_state.client_screen === 'forza4' ? 'Stop' : 'Play'}
                        </Button>
                    </div>
                    <Row className='mt-3'>
                        <CardButton link='forza4/round/1' title='ROUND 1'/>
                        <CardButton link='forza4/round/2' title='ROUND 2'/>
                        <CardButton link='forza4/round/3' title='ROUND 3'/>
                    </Row>
                </Container>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        current_state: state.header.current_state,
        unsubscribes: state.forza4.unsubscribes
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSystem: () => dispatch(headerActions.getSystem())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Forza4);
