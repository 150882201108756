import React, {Component} from 'react';
import Header from '../Components/Header';
import {Button, Container, Row, Col} from 'react-bootstrap';
import {forza4Actions} from '../store/actions/forza4Action';
import {connect} from 'react-redux';
import Forza4Table from '../Components/Forza4Table';
import CommunicationController from '../firebase/CommunicationController';

class Round extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: parseInt(this.props.match.params.round),
            reset_done: false
        }
    }

    componentDidMount() {
        document.title = `Nucalathon | Forza 4 - Round ${this.state.id}`;
        this.props.getForza4();
        this.props.getRound(this.state.id);
        this.props.getTables();
    }

    componentWillUnmount() {
        for (const unsubscribe in this.props.unsubscribes) {
            if (unsubscribe !== 'get_matches') {
                this.props.unsubscribes[unsubscribe]();
            }
        }
    }

    handlePlayStopClick = async () => {
        if (this.props.forza4.current_round.id_round === parseInt(this.state.id)) {
            let response;
            if (this.props.forza4.current_round.live) {
                response = await CommunicationController.INSTANCE.stopRound(this.state.id);
            } else {
                response = await CommunicationController.INSTANCE.startRound(this.state.id);
            }

            if (!response.ok) {
                console.error(await response.text());
                alert('Error');
            }
        }
    }

    handleSetupClick = async () => {
        let response = await CommunicationController.INSTANCE.setupForza4Round(this.state.id);
        if (!response.ok) {
            console.error(await response.text());
            alert('Error');
        }
    }

    handleResetClick = async () => {
        if (confirm('Are you sure you want to reset the round?')) {
            let response = await CommunicationController.INSTANCE.resetForza4Round(this.state.id);
            if (response.ok) {
                this.setState({reset_done: true});
                setTimeout(() => this.setState({reset_done: false}), 3000);
            } else {
                console.error(await response.text());
                alert('Error');
            }
        }
    }

    handleUpdateResultClick = async () => {
        if (confirm('Are you sure you want to update results?')) {
            let response = await CommunicationController.INSTANCE.updateResultsForza4Round({roundId: this.state.id});
            if (!response.ok) {
                console.error(await response.text());
                alert('Error');
            }
        }
    }

    getMatch = (match, i) => {
        return (
            <Col sm={6} className={`${i > 1 ? 'mt-4' : ''} ${i % 2 === 0 ? 'ps-0' : 'pe-0'}`} key={i}>
                <Forza4Table round={this.state.id} tables={this.props.tables} match={match}/>
            </Col>
        );
    }

    render() {
        return (
            <>
                <Header page='forza4'/>
                <Container className='mt-3' fluid>
                    <h3>{`FORZA 4 - ROUND ${this.state.id}`}</h3>
                    <div className='d-flex align-items-center mt-3'>
                        <h5 className='me-2 mb-0'>Round {this.state.id} setup:</h5>
                        <Button
                            variant={this.props.round && this.props.round.setup_done ? 'danger' : 'success'}
                            disabled={this.props.round && this.props.round.setup_done}
                            onClick={async () => await this.handleSetupClick()}>
                            {this.props.round && this.props.round.setup_done ? 'Setup done' : 'Setup'}
                        </Button>
                    </div>
                    <div className='d-flex align-items-center mt-3'>
                        <h5 className='me-2 mb-0'>Round {this.state.id} action:</h5>
                        <Button
                            variant={this.props.forza4.current_round && this.props.forza4.current_round.id_round === this.state.id && this.props.forza4.current_round.live ? 'danger' : 'success'}
                            disabled={this.props.round && (this.props.round.played || !this.props.round.setup_done)}
                            onClick={async () => await this.handlePlayStopClick()}>
                            {this.props.forza4.current_round && this.props.forza4.current_round.id_round === this.state.id && this.props.forza4.current_round.live ? 'Stop' : 'Play'}
                        </Button>
                    </div>
                    <div className='d-flex align-items-center mt-3'>
                        <h5 className='me-2 mb-0'>Round {this.state.id} reset:</h5>
                        <Button
                            variant={this.state.add_table_bonus_done ? 'success' : 'danger'}
                            disabled={this.props.forza4.current_round && (this.props.forza4.current_round.id_round !== this.state.id || (this.props.forza4.current_round && this.props.round && this.props.forza4.current_round.id_round === this.state.id && (this.props.forza4.current_round.live || !this.props.round.setup_done)))}
                            onClick={async () => await this.handleResetClick()}>
                            Reset
                        </Button>
                    </div>
                    <div className='d-flex align-items-center mt-3'>
                        <Button
                            variant='info'
                            disabled={(this.props.round && this.props.round.played) || (!(_.isEmpty(this.props.forza4)) && this.props.forza4.current_round.id_round === this.state.id && this.props.forza4.current_round.live)}
                            onClick={async () => await this.handleUpdateResultClick()}>
                            Update Results
                        </Button>
                    </div>

                    <Row className='mt-3 mx-0'>
                        {this.props.round && this.props.round.tables_opponents && this.props.round.tables_opponents.map((match, i) => this.getMatch(match, i))}
                    </Row>
                </Container>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        forza4: state.forza4.forza4,
        round: state.forza4.round,
        tables: state.forza4.tables,
        unsubscribes: state.forza4.unsubscribes
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getForza4: () => dispatch(forza4Actions.getForza4()),
        getRound: id => dispatch(forza4Actions.getRound(id)),
        getTables: () => dispatch(forza4Actions.getTables()),
        resetMatches: () => dispatch(forza4Actions.resetMatches())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Round);
